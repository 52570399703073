.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.Login {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 400px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    .validationError {
        color: red;
        margin-bottom: 10px;
        text-align: center;
      }

    .logoContainer {
        margin-bottom: 20px;
        display: flex;
        align-items: center;

        span {
            color: black;
        }
    }



    .logo {
        width: 4rem;
        height: auto;
    }

    h1 {
        margin: 0;
        color: #333;
    }

    .subtitle {
        color: #666;
        margin-bottom: 20px;
    }

    .loginForm {
        width: 100%;
        padding: 0 20px;

        .inputGroup {
            margin-bottom: 15px;

            input {
                width: 100%;
                padding: 10px;
                border: 1px solid #ccc;
                border-radius: 4px;
                box-sizing: border-box;

                &:focus {
                    border-color: #007bff;
                    outline: none;
                }
            }
        }

        .continueButton {
            width: 100%;
            padding: 10px;
            border: none;
            background-color: #007bff;
            color: white;
            border-radius: 4px;
            cursor: pointer;

            &:hover {
                background-color: darken(#007bff, 10%);
            }
        }
    }
}