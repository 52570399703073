
@mixin font-size($size, $elem, $font-size: true, $line-height: false, $kerning: false, $adjust-font-size: 0) {
	@if not map-has-key(map-get($font__scale, $elem), $size) {
		@warn "'#{$size}' key does not exist in array!";
	}
	@if ( $font-size != false ) {
		font-size: get-font-size($size, $elem) + $adjust-font-size;
	}
	@if ( $line-height == true ) {
		line-height: get-line-height($size, $elem);
	}
	@if ( $kerning == true ) {
		letter-spacing: get-kerning($size, $elem);
	}
}

@mixin font-family($elem) {
	font-family: unquote(get-font-family($elem));
}

@mixin font-weight($elem) {
	font-weight: get-font-weight($elem);
}

@mixin anchor-aspect($type: 'main') {
	@if ($type == 'main') { 
		color: color(typography, 2);
		text-decoration: underline;

		&:hover,
		&:active {
			outline: 0;
			text-decoration: none;
		}
	} @else if ($type == 'header') {
		color: color(typography, 2);
		text-transform: uppercase;
		text-decoration: none;

		&:hover,
		&:active {
		}
	} @else if ($type == 'footer') {
		color: color(typography, 2);
		text-decoration: none;

		&:hover,
		&:active {
			text-decoration: underline;
		}
	}
}

@mixin shadow {
	box-shadow: 0 24px 48px rgba(color(bg, 1), .24);
	mix-blend-mode: multiply;
}

@mixin shadow-sm {
	box-shadow: 0 16px 24px rgba(color(bg, 1), .24);
	mix-blend-mode: multiply;
}

@mixin divider-mix {
	display: block;
	height: 1px;
	background: color(bg, 3);
}

@mixin divider($type: false) {
	@if ( $type == 'before' ) {
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			@include divider-mix;
		}
	} @else if ($type == 'after') {
		position: relative;

		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			@include divider-mix;
		}
	} @else {
		@include divider-mix;
	}
}

@mixin media-breakpoint-down($size) {
	@if $size == sm {
	  @media (max-width: 576px) { @content; }
	} @else if $size == md {
	  @media (max-width: 768px) { @content; }
	} @else if $size == lg {
	  @media (max-width: 992px) { @content; }
	} @else if $size == xl {
	  @media (max-width: 1200px) { @content; }
	}
  }
  
