@import "../../assets/scss/abstracts/variables";
@import "../../assets/scss/abstracts/functions";
@import "../../assets/scss/abstracts/mixins";

.features-section {
    background: color(bg, 2);
}

.features-section .section-title {
    text-align: center;
    margin-bottom: 3rem;
    color: #FFFFFF;
}

.shadowed-container>.d-flex {
    justify-content: flex-start;
    align-items: center;
    text-align: left;
}

.shadowed-container {
    background-color: #2C3039;
    box-shadow: 0 24px 48px rgba(21, 24, 29, 0.24);
    padding: 20px;
    border-radius: 4px;
    color: #FFF;
    height: 100%;
}

.shadowed-container>.d-flex {
    display: flex;
    align-items: center;
    text-align: left;
    min-height: 120px;
    
    
}

.feature-icon {
    flex-shrink: 0;
    flex-grow: 0;
}

.feature-icon img {
    width: 60px;
    height: 60px;
}

.ms-3 {
    flex-grow: 1;
    min-width: 0;
}

.feature-title {
    color: #FFFFFF;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-size: 20px;
}

.key-phrases {
    color: #FFFFFF;
    font-size: 0.9rem;
    margin-top: 0.5rem;
}

.feature-description {
    color: #FFFFFF;
    text-align: left;
    margin: 0;
    font-size: 16px;
    line-height: 24px;
}

@media (max-width: 768px) {
    .feature.shadowed-container {
        margin-bottom: 1rem;
    }
}

.value-container {
    background-color: #2C3039;
    background-size: cover;
    background-position: center;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 0.5rem;
    color: #EFEFEF;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    
    img {
        width: 100%;
        height: auto;
        min-height: 155px;
        max-width: 150px;
        margin-bottom: 1rem;
    }
    
    p {
        font-size: 1rem;
        color: #EFEFEF;
        background-color: transparent;
        padding: 0;
        margin: 0;
        text-align: center;
    }
}

.title-container {
    display: block !important;
}