.experience-section {
  .experience-title {
    text-align: left;
    margin-bottom: 20px;

    h1 {
      font-size: 2rem;
      color: white;
    }
  }

  .map-container {
    position: relative;
    width: 100%;
    max-width: 1000px;
    margin: 3rem auto 0;
    padding: 1rem;

    .world-map {
      width: 100%;
      height: auto;
      max-height: 1000px;
    }

    .info-spot {
      position: absolute;
      color: rgb(228, 42, 42);
      height: 15px;
      width: 15px;
      font-size: 20px;
      background-color: green;
      border-radius: 50%;
      box-shadow: none;
      border: 3px solid white;
      ;
      transition: box-shadow 0.3s ease-in-out, transform 0.3s ease;

      .info-spot-tooltip {
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        background-color: #ffffff;
        color: #000000;
        padding: 5px 10px;
        border-radius: 4px;
        white-space: nowrap;
        z-index: 10;

        &::after {
          content: '';
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: #ffffff transparent transparent transparent;
        }

        .tooltip-country {
          font-size: 1rem;
          font-weight: 600;
          color: #333;
          text-align: center;
        }

        .tooltip-projects {
          font-size: 0.9rem;
          color: #666;
          text-align: center;
          padding-top: 4px;
        }
      }

      &:hover,
      &.active {
        border: 4px solid #d0d0d0;
        box-shadow: 0 0 0 2px white;
        cursor: pointer;
      }
    }
  }


  .info-section {

    .country-heading {
      font-size: 1.5rem;
      color: white;
      margin-bottom: 1rem;
      text-align: left;
    }



  }

  .project-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;
    padding: 1rem;
  }

  .project-card {
    background-color: #2C3039;
    color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    width: calc(100% - 40px);

    @media (min-width: 768px) {
      width: calc(50% - 40px);
    }

    @media (min-width: 1024px) {
      width: calc(33.333% - 40px);
    }

    .project-year,
    .project-title,
    .project-description,
    .project-role {
      margin-bottom: 10px;
    }

    .project-title {
      font-size: 1.25rem;
      font-weight: bold;
    }

    .project-description {
      font-size: 0.9rem;
      opacity: 0.8;
    }

    .project-role {
      font-style: italic;
      opacity: 0.7;
    }
  }



}