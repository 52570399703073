@import "../../assets/scss/abstracts/variables";
@import "../../assets/scss/abstracts/functions";
@import "../../assets/scss/abstracts/mixins";

.getting-started-section {
    background-color: #121c2d;
    background-image: linear-gradient(to top, #0d2143 0%, #121c2d 75%);
    padding: 40px 0;

    .column-text {
        font-size: 1.3em;
    }

    h2 {
        font-size: 1.5em;
    }

    .image-align {
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 767px) {
            margin-top: 30px;
        }
    }

    button {
        background-color: #007bff;
        color: #fff;
        border: none;
        padding: 10px 20px;
        font-size: 16px;
        cursor: pointer;
        border-radius: 5px;
        transition: background-color 0.3s ease;
    }
    
    button:hover {
        background-color: #0056b3;
    }
}



