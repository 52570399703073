@import "../../assets/scss/abstracts/variables";
@import "../../assets/scss/abstracts/functions";
@import "../../assets/scss/abstracts/mixins";

.Footer {
    padding: 20px;
    background-color: color(bg, 2);
    color: white;
    text-align: center;

    .Content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }

    .ContactInfo {
        display: flex;
        align-items: center;
    
        .LinkedinLink {
            display: flex;
            align-items: center;
            color: white;
            text-decoration: none;
            
            &:hover {
                color: #0077b5;
            }
        }
    }
    


}