@import "../../assets/scss/abstracts/variables";
@import "../../assets/scss/abstracts/functions";
@import "../../assets/scss/abstracts/mixins";

.is-boxed {
    background: color(bg, 3);
}

.sr {

    .has-animations {

        .is-revealing {
            visibility: hidden;
        }
    }
}

.has-animations {

    .anime-element {
        visibility: hidden;

        .anime-ready & {
            visibility: visible;
        }
    }
}

.body-wrap {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}