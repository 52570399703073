@import "../../assets/scss/abstracts/variables";
@import "../../assets/scss/abstracts/functions";
@import "../../assets/scss/abstracts/mixins";

$navbar-background: linear-gradient(65deg, rgb(2, 112, 215) 0, rgb(15, 138, 253) 100%);
$navbar-item-color: #FFFFFF;
$navbar-item-hover-color: linear-gradient(65deg, #0270D7 0, #0F8AFD 100%);
$mobile-breakpoint: 768px;
  


.site-header-inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-links {
    display: inline-flex;

    li {
        display: inline-flex;
    }

    a:not(.button) {
        @include font-size(7, mobile, true, true, true);

        @if (get-font-size(7, desktop) !=get-font-size(7, mobile)) {
            @include media('>medium') {
                @include font-size(7, desktop, true, true, true);
            }
        }

        @include font-weight(semibold);
        @include anchor-aspect(header);
        line-height: 16px;
        padding: 8px 24px;
    }
}

@media(min-width: 769px) {

    .site-header {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1000;
        background: color(bg, 2);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
}

.company-name {
    font-size: 1.5rem;
    color: white;
    margin-left: 10px;
}

.navbar {
    z-index: 100;
}

.navbar-custom {
    background: transparent;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    padding: 0 16px;
    min-height: 64px;

    .navbar-brand {
        display: flex;
        align-items: center;

        img {
            width: 4rem;
            height: auto;
        }
    }

    .navbar-nav {
        .nav-item {
            .nav-link {
                color: $navbar-item-color;
                padding: 0 15px;
                line-height: 64px;
                transition: background-color 0.3s;

                .dropdown-arrow {
                    transition: transform 0.3s;
                }

                &:hover {
                    color: #0F8AFD;
                    text-decoration: underline;
                    text-decoration-color: #0F8AFD;
                    text-decoration-thickness: 5px;
                    text-underline-offset: 8px;
                    color: $navbar-item-hover-color;

                    .dropdown-arrow {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    .navbar-toggler {
        border-color: rgba(255, 255, 255, 0.5);

        icon {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23FFFFFF' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
        }
    }
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    
}

.navbar-nav-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
}

.navbar-right {
    color: white;
    display: flex;
    align-items: center;

    .login-button {
        padding: 8px 26px;
        transition: background-color 0.3s;
        border-radius: 4px;
        margin-left: 8px;
        background-color: #cd2026;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        font-weight: 500;
    }

    .login-button:hover {}
}

.login-button {

    &:focus,
    &:hover {
        background-color: #cd2026;
        opacity: 0.8;
    }
}

.navbar-toggler {
    &:focus {
        box-shadow: none !important;
    }
}

@media (max-width: 990px) {
    .site-header .navbar-nav-container {
        justify-content: left;
    }

    .login-button {
        width: 100%;
        text-align: center;
        margin-top: 16px;
        margin-bottom: 8px;
    }
}