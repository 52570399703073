@import "../../assets/scss/abstracts/variables";
@import "../../assets/scss/abstracts/functions";
@import "../../assets/scss/abstracts/mixins";

.LatestUpdates {
    color: #ffffff; 
    padding: 20px; 
    .card {
      background-color: #2C3039; 
      border: 3px solid #2C3039; 
      color: #ffffff; 
      margin-bottom: 20px; 
  
      .card-img-top {
        height: 200px;
        object-fit: cover;
      }
  
      .cardBody {
        flex: 1; 
        display: flex;
        flex-direction: column;
        justify-content: space-between; 
  
        .card-title {
          color: #4caf50; 
        }
  
        .text-muted {
          color: #bbbbbb; 
        }
  
        hr {
          border-color: #b0b0b0; 
        }
  
        .card-text {
          flex-grow: 1; 
        }
  
        .btn-primary {
          background: mix(color(primary, 1), color(primary, 2));
          background: linear-gradient(65deg, color(primary, 1) 0, color(primary, 2) 100%);
          border: none; 
          &:hover {
            background-color: #0056b3; 
          }
        }
      }
    }
  }
  