@import "../../assets/scss/abstracts/variables";
@import "../../assets/scss/abstracts/functions";
@import "../../assets/scss/abstracts/mixins";

.pricing-section {
  background: color(bg, 2);

  .section-title {
    color: #FFFFFF; 
  }

  .pricing-table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3); 
    border-radius: 1rem;
    overflow: hidden;
    background-color: #343A40; 

    th,
    td {
      text-align: center;
      padding: 0.75rem;
      border: 1px solid #454D55; 
      color: #E1E1E1; 
    }

    thead th {
      background-color: #3E444A; 
      color: #FFFFFF; 
      font-weight: 600;
    }

    tbody tr {
      background-color: #343A40; 

      &:last-child {
        td:first-child {
          border-bottom-left-radius: 1rem;
        }

        td:last-child {
          border-bottom-right-radius: 1rem;
        }
      }
    }

    tbody tr th {
      background-color: #3E444A; 
      color: #FFFFFF; 

      &:hover {
        cursor: pointer;
        background-color: #464E54; 
      }
    }

    .clickable:hover {
      background-color: #464E54; 
      color: #FFFFFF; 
    }

    .button {
      background-color: #007bff; 
      color: #fff; 
      &:hover {
        background-color: #0056b3; 
      }
    }

    .button.button-primary {
      
    }
  }
}
