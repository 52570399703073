.vision-mission-section {
  padding: 2rem 0;
  background-color: rgb(18, 28, 45);

  .section-heading {
    color: #EFEFEF;
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    font-weight: 300;
  }

  .title-container {
    background-color: transparent;
  }

  .vision-container,
  .mission-container {
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: rgb(23, 35, 56);
    margin-bottom: 10px;
    color: #EFEFEF;

    h2 {
      margin-top: 0.5rem;
      margin-bottom: 1rem;
      color: #0F8AFD;
    }
  }

  .value-row {}

  .value-container {
    background-color: #13364D;
    background-size: cover;
    background-position: center;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 0.5rem;
    color: #EFEFEF;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);

    img {
      width: 100%;
      height: auto;
      min-height: 155px;
      max-width: 150px;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1rem;
      color: #EFEFEF;
      background-color: transparent;
      padding: 0;
      margin: 0;
      text-align: center;
    }
  }

}