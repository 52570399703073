@import "../../assets/scss/abstracts/variables";
@import "../../assets/scss/abstracts/functions";
@import "../../assets/scss/abstracts/mixins";

.hero {
	text-align: center;
    padding-top: 48px;
	padding-bottom: 88px;
	background: color(bg, 2);
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 700px;
		background: color(bg, 3);
		background: linear-gradient(80deg, rgba(color(bg, 3), .5) 0%, rgba(color(bg, 3), 0) 100%);
		-webkit-transform-origin: 0;
		transform-origin: 0;
		-webkit-transform: skewY(-8deg);
		transform: skewY(-8deg);
	}
}

.hero-copy {
    position: relative; 	z-index: 1;
}

.hero-cta {
	margin-bottom: 40px;
}

.hero-figure {
	position: relative;

	svg {
		width: 100%;
		height: auto;
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		background-repeat: no-repeat;
		background-size: 100%;

		.has-animations & {
			opacity: 0;
			transition: opacity 2s ease;

			.anime-ready & {
				opacity: 1;
			}
		}
	}
}

.hero-figure-box {
	position: absolute;
	top: 0;
	will-change: transform;
}

.hero-figure-box-01,
.hero-figure-box-02,
.hero-figure-box-03,
.hero-figure-box-04,
.hero-figure-box-08,
.hero-figure-box-09 {
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		transform-origin: 100% 100%;
	}
}

.hero-title {
	color: white;
}

.hero-paragraph {
	color: #d5d5d5;
}

@media( max-width: 768px ) {

	.hero-cta {
		max-width: 280px;
		margin-left: auto;
		margin-right: auto;

		.button {
			display: flex;

			+ .button {
				margin-top: 16px;
			}
		}
	}

	.hero-figure::after,
	.hero-figure-box-03,
	.hero-figure-box-04,
	.hero-figure-box-09 {
		display: none;
	}
}

@media(min-width: 769px) {

    .hero {
		text-align: left;
        padding-top: 64px;
        padding-bottom: 88px;
    }

	.hero-inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 95%;
	}

    .hero-copy {
		padding-right: 64px;
        min-width: 552px;
        width: 552px;
    }

	.hero-cta {
		margin: 0;

		.button {
			min-width: 170px;

			&:first-child {
				margin-right: 16px;
			}
		}
	}

	.hero-figure {

		svg {
			width: auto;
		}
	}
}

.button {
    display: inline-flex;
    @include font-size(8, mobile, true, false, true);
    @if ( get-font-size(8, desktop) != get-font-size(8, mobile) ) {
        @include media( '>medium' ) {
            @include font-size(8, desktop, true, false, true);
        }
    }
    @include font-weight(semibold);
    line-height: 16px;
    text-decoration: none !important;
	text-transform: uppercase;
    background-color: color(bg, 3);
    color: color(typography, 1) !important;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    justify-content: center;
    padding: 16px 32px;
	height: 48px;
    text-align: center;
    white-space: nowrap;

	&:hover {
		background: lighten(color(bg, 3), 1%);
	}

    &:active {
        outline: 0;
    }

    &::before {
        border-radius: 2px;
    }
}

.button-sm {
    padding: 8px 24px;
	height: 32px;
}

.button-primary {
	background: mix(color(primary, 1), color(primary, 2));
    background: linear-gradient(65deg, color(primary, 1) 0, color(primary, 2) 100%);

    &:hover {
		background: lighten(mix(color(primary, 1), color(primary, 2)), 2%);
        background: linear-gradient(65deg, lighten(color(primary, 1), 2%) 0, lighten(color(primary, 2), 2%) 100%);
    }
}

.button-block {
    display: flex;
}

.button-block {
    display: flex;
	width: 100%;
}

@media(max-width: 768px) {

	.button-wide-mobile {
		width: 100%;
		max-width: 280px;
	}
}
