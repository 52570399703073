.timeline-section .timeline-container {
    position: relative;


    &:before {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        bottom: 0;
        width: 2px;
        background-color: #dee2e6;
        z-index: 1;
    }

    .timeline-box {
        position: relative;
        background: #2C3039;
        color: white;
        border-radius: .375rem;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
        margin-bottom: 2rem;
        padding: 1rem;
        width: 45%;

        &:nth-child(even) {
            margin-left: 0;
            margin-right: auto;
            display: flex;
            flex-direction: row-reverse;
            justify-content: end;
        }

        &:nth-child(odd) {
            margin-left: auto;
            margin-right: 0;
        }

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: #007bff;
            z-index: 2;
            transform: translateY(-50%);
        }

        &:nth-child(odd):before {
            right: 100%;
            margin-right: 20px;
        }

        .timeline-date {
            padding: 6px 12px 6px 12px;
            background-color: #007bff;
            border-radius: 6px;
            margin-left: 20px;
        }

        h2 {
            font-size: 24px;
            margin: 0;
        }

        p {
            font-size: 16px;
        }
    }
}

.section-heading {
    color: #EFEFEF;
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    font-weight: 300;
}

.timeline-box,
.timeline-box-right {
    padding: 2rem;
    display: flex;
    align-items: center;

    .timeline-content {
        padding-left: 1.5rem;
    }

    .timeline-box-right .timeline-content {
        padding-left: 0;
        padding-right: 1.5rem;
    }
}

@media (min-width: 992px) {

    .timeline-box {
        &:nth-child(even):before {
            left: 100%;
            margin-left: 20px;
        }
    }

}


@media (max-width: 991px) {
    .timeline-section .timeline-container {

        &:before {
            left: -10px;
        }

        .timeline-box {
            width: 90%;
            margin-left: auto;
            margin-right: 20px;

            .timeline-date {
                margin: 0;
            }

            .timeline-content {
                padding-left: 1rem;
            }

            &:nth-child(even):before {
                right: 100%;
                margin-right: 20px;
            }

            &:nth-child(even) {
                margin-left: auto;
                margin-right: 0;
                flex-direction: row;
                justify-content: end;
            }
        }
    }


}